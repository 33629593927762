<template>
  <section>
    <uspCartao titulo="">
      <template #cabecalho>
        {{ titulo }}
      </template>
      <template 
        #corpo 
        ref="conteudo"
      >
        <div class="col-xs d-inline-block mb-3">
          <select 
            v-model="idioma" 
            class="form-control"
          >
            <option value="">
              Idioma da página: Português
            </option>
            <option value="I">
              Page language: English
            </option>
          </select>
        </div>
        <div v-if="idioma == 'I'">
          <p>University of São Paulo provides, on this page, a place to verify the authenticity of documents issued by the university through the Internet.</p>
          <p class="mt-4">
            Type below the document's VERIFICATION CODE:
          </p>
          <div 
            id="hashcodecontainer" 
            class="mt-4"
          >
            <form @submit.prevent="enviar">
              <template v-for="n in 16">
                <span
                  :key="n"
                  class="hashcodechar d-inline-block mr-1 p-1 border border-2 text-center align-middle user-select-none"
                  :class="{ posicaoselecionado: posicao == (n-1), disabled: habilitarLimparConsulta }"
                  :data-posicao="n-1"
                  @click="selecionaposicao"
                >
                  {{ code[n-1] }}
                </span>
                <span 
                  v-if="[4,8,12].includes(n)"
                  class="hashcodechar d-inline-block text-center align-middle user-select-none"
                  :class="{ disabled: habilitarLimparConsulta }"
                  :key="n + '_'"
                >
                  -
                </span>
              </template>

              <input
                v-model="code"
                ref="hashcodeinput"
                @keypress="inputcursorposition"
                @keydown="inputcursorposition"
                @keyup="inputcursorposition"
                @focus="inputcursorposition"
                @blur="inputcursorposition"
                style="width: 0; height: 0; opacity: 0;"
                :disabled="habilitarLimparConsulta"
                :readonly="habilitarLimparConsulta"
              >

              <button type="button" class="btn btn-info ml-3" v-if="habilitarLimparConsulta" @click.prevent="limparConsulta">New query</button>
              <button type="submit" class="btn btn-primary ml-3" v-else :disabled="!habilitarConsulta || carregando">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="carregando"
                ></span>
                SUBMIT
              </button>
            </form>
          </div>

          <object
            :data="documento"
            type="application/pdf"
            class="w-100 vh-100 mt-4"
            v-if="!!documento"
          >
            <a
              :href="documento"
              :download="'documento_' + code + '.pdf'"
              class="btn btn-sm btn-primary mt-4"
            >
              Document download
            </a>
          </object>
          
          <uspCartao
            :titulo="'This document is ' + (dataInvalidacao ? 'invalid' : 'valid')"
            :classes="{'cardHeader': (dataInvalidacao ? 'bg-danger' : 'bg-success')}"
            v-if="!!dataValidacao"
            :fechavel="false"
            class="mt-4"
          >
            <template #cabecalho>
              <h5 class="text-white">
                This document is {{ dataInvalidacao ? 'invalid' : 'valid' }}
              </h5>
            </template>
            <template #corpo>
              <div>
                <div><span style="font-weight: bold">Name:</span> {{ nomeDocumento }}</div>
                <div><span style="font-weight: bold">Size:</span> {{ tamanhoDocumentoFormatado }} bytes</div>
                <div><span style="font-weight: bold">Hash:</span> {{ checksumDocumento }}</div>
                <div><span style="font-weight: bold">Validated by:</span> {{ codigoPessoaCadastro }} - {{ nomePessoaCadastro }} on {{ dataValidacaoComputed }}</div>
                <div><span style="font-weight: bold">Organizational unit:</span> {{ nomeUnidadeIngles }} ({{ siglaUnidade }})</div>
                <div class="text-danger" v-if="dataInvalidacao"><span style="font-weight:bold">Invalidated on:</span>  {{ dataInvalidacaoComputed }}</div>
              </div>
            </template>
          </uspCartao>

          <uspAlerta variante="perigo" v-show="!!erro" class="mt-4">
            {{ erro }}
          </uspAlerta>

          <div class="mt-4" v-if="!dataValidacao">
            <p>There are 3 possible search outcomes:</p>

            <ol>
              <li class="mt-4">
                The submitted VERIFICATION CODE corresponds to a valid document issued by USP.
                A faithful copy of the document will appear on the screen, allowing you to verify its authenticity.
              </li>
              <li class="mt-4">
                The submitted VERIFICATION CODE corresponds to an expired document issued by USP.
                You'll see a message displaying the type, emission date and expiration date of the document on your screen.
              </li>
              <li class="mt-4">
                The submitted VERIFICATION CODE does not correspond to any document issued by USP.
              </li>
            </ol>

            <div class="text-center">
              <router-link
                :to="{ name: 'Iddigital:tiposdocumentos' }"
                tag="button"
                class="btn btn-success mt-2 mb-2"
              >
                List of documents issued by the University of São Paulo through the Internet
              </router-link>
            </div>
          </div>

        </div>
        <div v-else>
          <p>A Universidade de São Paulo oferece, nesta página, a possibilidade de conferir a autenticidade de um documento emitido por ela através da internet.</p>

          <p class="mt-4">Digite abaixo o "Código de controle" que pode ser encontrado no documento recebido.</p>

          <div id="hashcodecontainer" class="mt-4">
            <form @submit.prevent="enviar">
              <template v-for="n in 16">
                <span
                  :key="n"
                  class="hashcodechar d-inline-block mr-1 p-1 border border-2 text-center align-middle user-select-none"
                  :class="{ posicaoselecionado: posicao == (n-1), disabled: habilitarLimparConsulta }"
                  :data-posicao="n-1"
                  @click="selecionaposicao"
                >
                  {{ code[n-1] }}
                </span>
                <span 
                  v-if="[4,8,12].includes(n)"
                  class="hashcodechar d-inline-block text-center align-middle user-select-none"
                  :class="{ disabled: habilitarLimparConsulta }"
                  :key="n + '_'"
                >
                  -
                </span>
              </template>

              <input
                v-model="code"
                ref="hashcodeinput"
                @keypress="inputcursorposition"
                @keydown="inputcursorposition"
                @keyup="inputcursorposition"
                @focus="inputcursorposition"
                @blur="inputcursorposition"
                style="width: 0; height: 0; opacity: 0;"
                :disabled="habilitarLimparConsulta"
                :readonly="habilitarLimparConsulta"
              >

              <button type="button" class="btn btn-info ml-3" v-if="habilitarLimparConsulta" @click.prevent="limparConsulta">Nova consulta</button>
              <button type="submit" class="btn btn-primary ml-3" v-else :disabled="!habilitarConsulta || carregando">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="carregando"
                ></span>
                Validar
              </button>
            </form>
          </div>

          <object
            :data="documento"
            type="application/pdf"
            class="w-100 vh-100 mt-4"
            v-if="!!documento"
          >
            <a
              :href="documento"
              :download="'documento_' + code + '.pdf'"
              class="btn btn-sm btn-primary mt-4"
            >
              Download do documento
            </a>
          </object>
          
          <uspCartao
            :titulo="'Este documento é ' + (dataInvalidacao ? 'inválido' : 'válido')"
            :classes="{'cardHeader': (dataInvalidacao ? 'bg-danger' : 'bg-success')}"
            v-if="!!dataValidacao"
            :fechavel="false"
            class="mt-4"
          >
            <template #cabecalho>
              <h5 class="text-white">
                Este documento é {{ dataInvalidacao ? " inválido" : " válido" }}
              </h5>
            </template>
            <template #corpo>
              <div>
                <div><span style="font-weight: bold">Nome:</span> {{ nomeDocumento }}</div>
                <div><span style="font-weight: bold">Tamanho:</span> {{ tamanhoDocumentoFormatado }} bytes</div>
                <div><span style="font-weight: bold">Hash:</span> {{ checksumDocumento }}</div>
                <div><span style="font-weight: bold">Validado por:</span> {{ codigoPessoaCadastro }} - {{ nomePessoaCadastro }} em {{ dataValidacaoComputed }}</div>
                <div><span style="font-weight: bold">Unidade:</span> {{ nomeUnidade }} ({{ siglaUnidade }})</div>
                <div class="text-danger" v-if="dataInvalidacao"><span style="font-weight:bold">Invalidado em:</span>  {{ dataInvalidacaoComputed }}</div>
              </div>
            </template>
          </uspCartao>

          <uspAlerta variante="perigo" v-show="!!erro" class="mt-4">
            {{ erro }}
          </uspAlerta>

          <div class="mt-4" v-if="!dataValidacao">
            <p>Você poderá obter do sistema 3 três respostas diferentes.</p>

            <ol>
              <li class="mt-4">
                O "Código de controle" digitado no campo acima, corresponde a um documento emitido pela USP, e este ainda é válido.
                Neste caso você receberá na sua tela uma cópia fiel do documento em papel que lhe foi entregue, podendo assim confirmar a sua autenticidade.
              </li>
              <li class="mt-4">
                O "Código de controle" digitado no campo acima, corresponde a um documento emitido pela USP, mas este já perdeu a sua validade.
                Neste caso você receberá na sua tela uma mensagem constando o tipo de documento emitido, data de emissão, e quando o documento perdeu sua validade.
              </li>
              <li class="mt-4">
                O "Código de controle" digitado no campo acima, não corresponde a um documento emitido pela USP.
              </li>
            </ol>

            <div class="text-center">
              <router-link
                :to="{ name: 'Iddigital:tiposdocumentos' }"
                tag="button"
                class="btn btn-success mt-2 mb-2"
              >
                Lista de documentos emitidos pela Universidade de São Paulo através da Internet
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </uspCartao>
  </section>
</template>

<script>
import WebdocServico from "../dominio/WebdocServico.js";
import { StorageKeys } from '@/portal/constantes';

const api = WebdocServico.build({});
const separaQuatroRegex = /(.{4})(?!$)/g;

export default {
  name: "WebdocValidarDocumento",

  props: {
    codaucdoc: {
      type: String,
      default: "",
    },
  },
  
  data() {
    return {
      campo1: "",
      campo2: "",
      campo3: "",
      campo4: "",
      code: "",
      posicao: -1,
      idioma: "",
      documento: "",
      erro: "",
      carregando: false,
      executarConsultar: false,
      nomeDocumento: "",
      tamanhoDocumento: "",
      checksumDocumento: "",
      codigoPessoaCadastro: "",
      nomePessoaCadastro: "",
      dataValidacao: "",
      dataInvalidacao: "",
      nomeUnidade: "",
      nomeUnidadeIngles: "",
      siglaUnidade: ""
    };
  },

  computed: {
    titulo: function() {
      return this.idioma == "I" ? "Documents" : "Documentos";
    },
    codaucdocFormatado: function() {
      return this.codaucdoc.replaceAll(separaQuatroRegex, "$1-");
    },
    tamanhoDocumentoFormatado: function() {
      return this.tamanhoDocumento.split("").toReversed().reduce(
        (accumulator, currentValue, currentIndex) =>  currentValue + (currentIndex % 3 ? "" : ".") + accumulator
      );
    },
    dataValidacaoComputed: function() {
      const self = this;
      return this.idioma == "I" ?
              (
                self.dataValidacao.split("/")
                  .reduce(
                    (accumulator, currentValue, currentIndex) => {
                      return (
                        currentIndex == 1 ?
                          (currentValue + "/" + accumulator)
                          :
                          (accumulator + currentValue + "/")
                      ).substr(0,10);
                    },
                    ""
                  )
              )
              :
              this.dataValidacao;
    },
    dataInvalidacaoComputed: function() {
      const self = this;
      return this.idioma == "I" ?
              (
                self.dataInvalidacao.split("/")
                  .reduce(
                    (accumulator, currentValue, currentIndex) => {
                      return (
                        currentIndex == 1 ?
                          (currentValue + "/" + accumulator)
                          :
                          (accumulator + currentValue + "/")
                      ).substr(0,10);
                    },
                    ""
                  )
              )
              :
              this.dataInvalidacao;
    },
    habilitarConsulta: function() {
      return (this.code || "").length >= 16;
    },
    habilitarLimparConsulta: function() {
      return !!(this.documento || this.dataValidacao);
    }
  },

  watch: {
    code(newValue, oldValue) {
      this.posicao = document.activeElement === this.$refs.hashcodeinput ? this.$refs.hashcodeinput.selectionStart : -1;
      const diff = newValue.length - oldValue.length;
      if (oldValue.length && diff > 0) {
        this.code = ((newValue.substring(0, this.posicao) + newValue.substring(this.posicao + diff)) || "").substring(0,16);
      }
      else
      if (diff > 16) {
        this.code = newValue.substring(0,16)
      }
      //const self = this;
      //setTimeout(function() {self.$refs.hashcodeinput.setSelectionRange(self.posicao, self.posicao);},0);
    },

    documento(newValue, oldValue) {
      if (!newValue && oldValue) {
        URL.revokeObjectURL(oldValue);
      }
    },

    idioma(newValue) {
      if (newValue == "I") {
        this.$router.push({
          path: this.$route.path,
          query: { idioma: "I" }
        });
      } else {
        this.$router.push({
          path: this.$route.path
        });
      }
    },

    $route(to) {
      this.revogarBlobUrl();
      if (to.params.codaucdoc) {
        this.consultar();
      }
    },
  },

  created() {
    this.code = (this.$route.params.codaucdoc || "").replaceAll("-","").substr(0,16);
    this.idioma = this.$route.query.idioma || "";
  },
  
  mounted() {
    if (this.codaucdoc) {
      this.executarConsultar = true;
    } else if (document.activeElement !== this.$refs.hashcodeinput) {
      this.$refs.hashcodeinput.focus();
    }
  },

  updated() {
    if (this.posicao > -1) this.$refs.hashcodeinput.setSelectionRange(this.posicao, this.posicao);
  },

  unmounted() {
    this.revogarBlobUrl();
  },
  
  methods: {
    enviar() {
      if (this.codaucdoc != this.code && (this.code || "").length >= 16) {
        this.erro = "";
        this.dataValidacao = "";
        this.dataInvalidacao = "";
        this.executarConsultar = true;
        this.$router.push({
          name: "Iddigital:validadocweb",
          params: { codaucdoc: (this.code || "").replaceAll(separaQuatroRegex, "$1-") },
        });
      } else if (this.executarConsultar) {
        this.executarConsultar = false;
        this.consultar();
      }
    },
    
    consultar() {
      const autenticado = localStorage.getItem(StorageKeys.AUTH.USUARIO_DISPLAY);
      const self = this;
      const bean = {
        codaucdoc: self.codaucdoc,
        idioma: self.idioma
      }
      self.carregando = true;
      if (autenticado) {
        api.obterDocumento(bean)
          .then(blob => {
            if (self.codaucdoc.startsWith("0")) {
              self.tratarErroBlob(blob);
            } else {
              self.documento = URL.createObjectURL(blob);
            }
          })
          .catch(erro => self.tratarErroBlob(erro))
          .finally(() => (self.carregando = false));
      } else {
        // Execute reCAPTCHA with action "webdoc".
        self.$recaptcha("webdoc").then((token) => {
          bean.token = token;
          api.obterDocumento(bean)
            .then(blob => {
              if (self.codaucdoc.startsWith("0")) {
                self.tratarErroBlob(blob);
              } else {
                self.documento = URL.createObjectURL(blob);
              }
            })
            .catch(erro => self.tratarErroBlob(erro))
            .finally(() => (self.carregando = false));
        })
      }
    },

    limparConsulta() {
      this.erro = "";
      this.code = "";
      this.dataValidacao = "";
      this.dataInvalidacao = "";
      this.revogarBlobUrl();
      this.$router.push({
        name: "Iddigital:validadocweb",
        params: { codaucdoc: "" },
      });
    },

    selecionaposicao(event) {
      if (!this.documento) {
        this.$refs.hashcodeinput.selectionStart = event.target.dataset.posicao;
        this.$refs.hashcodeinput.selectionEnd = event.target.dataset.posicao;
        this.$refs.hashcodeinput.focus();
      }
    },
    
    inputcursorposition(event) {
      if (event.type == "blur") {
        this.posicao = -1;
        return;
      }
      if (this.$refs.hashcodeinput.selectionStart > 15) {
        this.$refs.hashcodeinput.setSelectionRange(15,15);
      }
      if (this.$refs.hashcodeinput.selectionStart != this.$refs.hashcodeinput.selectionEnd) {
        this.$refs.hashcodeinput.setSelectionRange(this.$refs.hashcodeinput.selectionStart || 0,this.$refs.hashcodeinput.selectionStart || 0);
      }
      this.posicao = this.$refs.hashcodeinput.selectionStart;
    },

    revogarBlobUrl() {
      if (this.documento) {
        URL.revokeObjectURL(this.documento);
        this.documento = "";
      }
    },

    tratarErroBlob(erro) {
      const self = this;
      erro.text()
        .then(value => {
          const objetoErro = JSON.parse(value);
          if ((objetoErro.mensagemDesenvolvedor || "").includes("error code [99999]")) {
            self.erro = objetoErro.mensagemDesenvolvedor.split("com.sybase.jdbc4.jdbc.SybSQLException:").pop().trim();
          } else if (self.codaucdoc.startsWith("0") && objetoErro.checksumDocumento) {
            [
              "nomeDocumento",
              "tamanhoDocumento",
              "checksumDocumento",
              "codigoPessoaCadastro",
              "nomePessoaCadastro",
              "dataValidacao",
              "dataInvalidacao",
              "nomeUnidade",
              "nomeUnidadeIngles",
              "siglaUnidade"
            ].forEach(tag => self[tag] = objetoErro[tag]);
          } else {
            self.erro = objetoErro.mensagem;
          }
        })
        .catch(error => {
          self.erro = error;
        });
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./src/componentes/estilos/temas.scss";

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    #hashcodecontainer .hashcodechar {
      width: 1.2em;
      height: 1.8em;
      font-size: 20px;
      color: map-get($tema, "usp-elemento-cor-do-texto");
      background-color: map-get($tema, "usp-elemento-cor-de-fundo");

      &.posicaoselecionado {
        filter: invert(100%);
        font-size: 20px;
      }

      &.disabled {
        opacity: .3
      }
    }
  }
}
</style>